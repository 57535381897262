import * as React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
//import { GatsbyImage } from "gatsby-plugin-image";
//import { Container } from 'react-bootstrap';
//import { Row } from 'react-bootstrap';
//import { Col } from 'react-bootstrap';

import WorkDetail from "../../components/parts/work-detail";
import WorkDetailHero from "../../components/parts/work-detail-hero";
import IframeYouTube from "../../components/parts/iframe-youtube";
//import IframeVimeo from "../../components/parts/iframe-vimeo";
//import WorkDetailImageGallery from "../../components/parts/work-detail-image-gallery";
//import WorkDetailImages from "../../components/parts/work-detail-images";
//import WorkDetailMiddleSection from "../../components/parts/work-detail-middle-section";
//import WorkDetailCredit from "../../components/parts/work-detail-credit";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/work-detail.scss";

const WorkDetailPage = ({ location, data }) => {

  //const imageNodes = data.images.edges;
  const ogpImage = (!data.ogpImage) ? null : data.ogpImage.publicURL;

  const HeroContent = () => {
    return (
      <WorkDetailHero className="">
        <IframeYouTube width="1280" height="720" id="k3toDmslunU" />
      </WorkDetailHero>
    );
  };

  const OutlineContent = () => {
    return (
      <React.Fragment>
        <p>
          2004年、元炭鉱夫・山本作兵衛翁の炭坑画をベースにして、明治から大正、昭和に至る炭鉱の人々の生活を描いたドキュメンタリーである。炭鉱は明治から昭和40年代まで、エネルギーの基幹産業として日本経済を支えてきた。しかし石油の登場によってその後すべて閉山に追い込まれていく。同時に、そこで暮らした人々の歴史も忘れ去られていった。
        </p>
        <p>
          作兵衛翁の炭坑画は、明治から昭和にかけて炭鉱の人々の暮らしや、仕事を克明に描いている。そして、描く炭鉱夫は、どこか清冽にして清廉なものを感じる。取材する中で炭鉱に生きた人々に共通する意識が私たちの心を捉えた。それは共同体という絆である。ある炭鉱夫が語る。
        </p>
        <p>
          「死という恐怖。それを常に身近に感じながら生きていくとき、最も重要なことは、仲間と「共に生きる」という意識であり意志である。」作兵衛翁が描いた筑豊には、私たちが再認識しなければならない日本人のアイデンティティーや共同体の香りが濃厚にただよっている。本作は、作兵衛翁が描く炭鉱の社会と、戦後の炭鉱社会で生きてきた人々の取材とが重なり合って進行する。そして、それらが織りなす世界からは、次のメッセージが明確に打ち出されていく。
        </p>
        <p>
          「いつの時代も、無辜にして無垢なる民によってこの国は支えられてきたのである」 と。<br />
          山本作兵衛翁の炭坑画は2011年ユネスコの世界記憶遺産に登録されました。<br />
          語り：小沢昭一　ナレーション窪田等<br />
          (文部科学省選定/文化庁支援作品)
        </p>
        <div className="links">
          <a href="https://www.amazon.co.jp/dp/B005ADK2EG/ref=as_sl_pc_qf_sp_asin_til?tag=mont-22&linkCode=w00&linkId=&creativeASIN=B005ADK2EG" target="_blank" rel="noreferrer">
            AmazonでDVD販売中！<span>↗</span>
          </a>
        </div>
        <div className="note">
          <small>
            ※学校・図書館・公共施設向けのDVD販売につきましては <Link to="/contact/">CONTACT</Link> からお問い合わせください。
          </small>
        </div>
      </React.Fragment>
    );
  };

  const summaryData = {
    scopes: ['All'],
    awards: [],
    outline: OutlineContent
  };

  return (
    <WorkDetail
      location={location}
      summary={summaryData}
      heroContent={HeroContent}
      ogpImage={ogpImage}
    >
      {/*
      <WorkDetailImageGallery>
        <WorkDetailImages images={imageNodes.slice(0, 1)} column={1} />
      </WorkDetailImageGallery>
      */}
    </WorkDetail>
  );
};

export default WorkDetailPage;

export const query = graphql`
  query {
    images: allFile(
      filter: {relativeDirectory: {eq: "works/yama"}}
      sort: {fields: name, order: ASC}
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1920)
            id
          }
        }
      }
    }
    ogpImage: file(relativePath: {glob: "works/ogp/yama.*"}) {
      publicURL
    }
  }
`;
